.Header {
    padding-left: 2px;
    margin-bottom: 1.5em;
}

.Header h1 {
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    color: rgba(var(--brand-dark-rgb), 1);

    border: var(--test-border-dotted);
    border-color: blue;
}

.Header h2 {
    font-size: 1.15em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(var(--brand-dark-rgb), .5);

    border: var(--test-border-dotted);
    border-color: blue;
}

.Header h3 {
    font-size: .9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(var(--brand-dark-rgb), .75);
    padding-top: .75em;

    border: var(--test-border-dotted);
    border-color: blue;
}