.Payment {
    display: flex;
    height: var(--full-height);
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--brand-dark-rgb), .05);

    border: var(--test-border-dotted);
    border-color: red;
}

.Container {
    display: flex;
    flex-direction: column;
    padding: 2em;

    border: var(--test-border-dotted);
    border-color: green;
}

@media (min-width: 765px) {
    .Container {
        max-width: 420px;
        min-width: 420px;
    }
}