.alert {
    display: flex;
    flex-direction: column;
    gap: .25em;
    padding: 1.25em 2em;
    box-shadow: 0px 0px 0px .2em rgba(var(--brand-dark-rgb), 0.25);
    border: 0;
    border-radius: calc(var(--br-inputs)/2);
    color: rgba(var(--brand-dark-rgb), 1);
    background-color: rgba(255, 255, 255, 0.85);
    margin-bottom: 2em;
}

.title {
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
}

.message {
    font-size: .9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Types */
.danger {
    color: rgba(var(--brand-danger-rgb), 1);
    /* border-color: rgba(var(--brand-danger-rgb), .65); */
    box-shadow: 0px 0px 0px .2em rgba(var(--brand-danger-rgb), 0.25);
    animation-name: ShakeDanger;
    animation-fill-mode: forward;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
}

@keyframes ShakeDanger {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes ShakeSuccess {
    0% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(0.375rem);
    }

    30% {
        transform: translateY(-0.375rem);
    }

    45% {
        transform: translateY(0.375rem);
    }

    60% {
        transform: translateY(-0.375rem);
    }

    75% {
        transform: translateY(0.375rem);
    }

    90% {
        transform: translateY(-0.375rem);
    }

    100% {
        transform: translateY(0);
    }
}


.success {
    color: rgba(var(--brand-success-rgb), 1);
    box-shadow: 0px 0px 0px .2em rgba(var(--brand-success-rgb), 0.25);
    animation-name: ShakeSuccess;
    animation-fill-mode: forward;
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
}