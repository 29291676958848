.Price {
    margin-bottom: 2em;
}

.Amount {
    font-size: 3em;
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    color: rgba(var(--brand-text-rgb),.75);
}

.Currency {
    font-size: 2.85em;
    color: rgba(var(--brand-text-rgb),.25);
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    margin: 0 .075em;
}

@media (min-width: 765px) {
    .Amount {
        font-size: 2.5em;
    }
    
    .Currency {
        font-size: 2.5em;
    }    
}