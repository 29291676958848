.Button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5em;
    cursor: pointer;
    text-decoration: none;
    padding: .75em 2em;
    background: rgba(var(--brand-dark-rgb), 1);
    color: rgba(var(--brand-light-rgb), 1);  
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 0;
    border-radius: var(--br-inputs);
    box-shadow: 0px 3px 10px 0px rgba(var(--brand-light-rgb), 0.15);
}

.Button:active {
    box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
}

form[disabled] .Button, .Button[disabled] {
    opacity: .65;
}

.Button[type="danger"] {
    background-color: rgba(var(--brand-danger-rgb), 1);
    color: rgba(var(--brand-light-rgb), 1);
}

.Button[type="success"] {
    background-color: rgba(var(--brand-success-rgb), 1);
    color: rgba(var(--brand-text-rgb), .5);
}

.Buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;
}

@media (min-width: 765px) {
    .Button {
        font-size: 1.1em;
        width: auto;
        box-shadow: none;
        transition: all 0.2s ease; 
    }
    
    .Button:hover {
        box-shadow: 0px 0px 0px .25em rgba(var(--brand-dark-rgb), 0.25);
    }
    
    form[disabled] .Button:hover, .Button[disabled]:hover {
        box-shadow: none;
        cursor: default;
    }
    
    .Button[type="danger"]:hover {
        box-shadow: 0px 0px 0px .25em rgba(var(--brand-danger-rgb), 0.25);
    }

    .Button[type="success"]:hover {
        box-shadow: 0px 0px 0px .25em rgba(var(--brand-success-rgb), 0.25);
    }

    .Buttons {
        padding-top: 0;
        flex-direction: row;
    }
}